// src/services/EventsService.js (Updated)
import axios from 'axios';

// Use the Drupal proxy endpoint
const EVENTS_API_URL = '/app/api/events';

/**
 * Service for fetching and handling event data
 */
class EventsService {
  /**
   * Fetch all events from the API
   * @returns {Promise<Array>} Array of events
   */
  async fetchEvents() {
    try {
      const response = await axios.get(EVENTS_API_URL);
      console.log('Raw events data:', response.data);
      return this.processEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
      throw error;
    }
  }

  /**
   * Decode HTML entities in a string
   * @param {string} text - Text with HTML entities
   * @returns {string} Decoded text
   */
  decodeHtmlEntities(text) {
    if (!text) return '';
    
    const element = document.createElement('div');
    element.innerHTML = text;
    return element.textContent || element.innerText || '';
  }

  /**
   * Process raw events data from the API
   * @param {Array} events - Raw events from API
   * @returns {Array} Processed events with formatted dates and fields
   */
  processEvents(events) {
    if (!Array.isArray(events)) {
      console.error('Expected events to be an array, got:', typeof events, events);
      return [];
    }

    console.log('Processing events, count:', events.length);
    
    // First, remove duplicate events based on title
    const uniqueEvents = this.removeDuplicates(events, 'title');
    console.log('After removing duplicates, count:', uniqueEvents.length);

    const processedEvents = uniqueEvents.map(event => {
      // Format dates for display and sorting
      const processedEvent = {
        ...event,
        title: this.decodeHtmlEntities(event.title), // Decode HTML entities in title
        body: this.decodeHtmlEntities(event.body), // Decode HTML entities in body
        dates: this.parseEventDates(event.field_event_date),
        firstDate: null, // Will be set below
        formattedDate: '',
        imageUrl: this.getImageUrl(event.field_event_image)
      };

      // Set the first date (for sorting and display in cards)
      if (processedEvent.dates && processedEvent.dates.length > 0) {
        processedEvent.firstDate = processedEvent.dates[0].start;
        
        // Format date for display (e.g., "Apr 10, 2025 • 7:30 PM")
        const date = new Date(processedEvent.firstDate);
        
        // Check if the date is valid
        if (!isNaN(date.getTime())) {
          const formattedDate = date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          });
          
          // Format time if available
          const formattedTime = date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
          });
          processedEvent.formattedDate = `${formattedDate} • ${formattedTime}`;
        } else {
          // If date is invalid, use a simple fallback
          console.warn('Invalid date for event:', processedEvent.title, processedEvent.dates[0].start);
          processedEvent.formattedDate = 'Date TBD';
        }
      } else {
        // If no dates are available, use a fallback
        processedEvent.formattedDate = 'Date TBD';
      }

      return processedEvent;
    });
    
    // Sort events by date and filter out past events
    const sortedAndFilteredEvents = processedEvents
      .sort((a, b) => {
        // Sort by date ascending (soonest first)
        if (a.firstDate && b.firstDate) {
          return new Date(a.firstDate) - new Date(b.firstDate);
        }
        return 0;
      })
      .filter(event => {
        // Filter out past events only if they have a valid date
        if (!event.firstDate) return true; // Keep events with no date
        
        const eventDate = new Date(event.firstDate);
        if (isNaN(eventDate.getTime())) return true; // Keep events with invalid dates
        
        const now = new Date();
        
        // Compare only the date components (year, month, day), not time
        // This will include events happening today, even if the time has passed
        const nowDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const eventDateOnly = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());
        
        return eventDateOnly >= nowDate;
      });

    console.log('Final processed events count:', sortedAndFilteredEvents.length);
    if (sortedAndFilteredEvents.length > 0) {
      console.log('First event:', sortedAndFilteredEvents[0].title, sortedAndFilteredEvents[0].formattedDate);
    }
    
    return sortedAndFilteredEvents;
  }

  /**
   * Remove duplicate events based on a property
   * @param {Array} events - Array of events
   * @param {string} prop - Property to check for duplicates
   * @returns {Array} Array with duplicates removed
   */
  removeDuplicates(events, prop) {
    const seen = new Set();
    return events.filter(event => {
      const value = event[prop];
      if (seen.has(value)) {
        return false;
      }
      seen.add(value);
      return true;
    });
  }

  /**
   * Parse event dates from the API string format
   * @param {string} dateString - String containing event dates
   * @returns {Array} Array of parsed date objects with start and end times
   */
  parseEventDates(dateString) {
    if (!dateString) return [];

    try {
      // Split multiple dates (some events have multiple dates)
      const dates = dateString.split(',').map(date => date.trim());
      
      return dates.map(dateStr => {
        try {
          // Handle custom date format that starts with T-XXXXX
          if (dateStr.includes('T-')) {
            // This is a special date format, try to extract meaningful parts
            const parts = dateStr.match(/(\d{4}-\d{2}-\d{2})T-\d+(\d{2}:\d{2}):\d{2}-(\d{2}:\d{2}):\d{2}/);
            
            if (parts) {
              const [_, datePart, startTimePart, endTimePart] = parts;
              const startDate = new Date(`${datePart}T${startTimePart}`);
              
              if (!isNaN(startDate.getTime())) {
                // Create end date by cloning start date and adding the end time
                const endDate = new Date(startDate);
                const [endHours, endMinutes] = endTimePart.split(':').map(num => parseInt(num, 10));
                endDate.setHours(endHours, endMinutes);
                
                return {
                  start: startDate,
                  end: endDate,
                  allDay: false
                };
              }
            }
          }
          
          // Handle standard ISO dates
          if (dateStr.includes('T') && !dateStr.includes('T-')) {
            const date = new Date(dateStr);
            return {
              start: date,
              end: null,
              allDay: false
            };
          }
          
          // Handle date ranges with '-'
          if (dateStr.includes('-')) {
            const [startPart, endPart] = dateStr.split('-').map(s => s.trim());
            
            // Create a date from the start part
            const startDate = new Date(startPart);
            
            if (isNaN(startDate.getTime())) {
              // Try one more parsing approach for the start date
              if (startPart.match(/^\d{4}-\d{2}-\d{2}$/)) {
                // If it's just a date without time, add a default time
                const startDateWithTime = new Date(`${startPart}T12:00:00`);
                return {
                  start: startDateWithTime,
                  end: null,
                  allDay: true
                };
              }
              
              console.warn('Unable to parse start date:', startPart);
              return null;
            }
            
            // If there's an end part, try to parse it
            if (endPart) {
              // Check if end part is just a time
              if (endPart.match(/^\d{1,2}:\d{2}(:\d{2})?(\s*[AP]M)?$/i)) {
                // It's just a time, so use the same date as start
                const endDate = new Date(startDate);
                
                // Parse the time part
                const timeParts = endPart.match(/(\d{1,2}):(\d{2})(?::(\d{2}))?(?:\s*([AP]M))?/i);
                if (timeParts) {
                  let [_, hours, minutes, seconds, ampm] = timeParts;
                  hours = parseInt(hours, 10);
                  minutes = parseInt(minutes, 10);
                  
                  // Adjust for AM/PM if present
                  if (ampm && ampm.toUpperCase() === 'PM' && hours < 12) {
                    hours += 12;
                  } else if (ampm && ampm.toUpperCase() === 'AM' && hours === 12) {
                    hours = 0;
                  }
                  
                  endDate.setHours(hours, minutes, seconds || 0);
                  return {
                    start: startDate,
                    end: endDate,
                    allDay: false
                  };
                }
              } else {
                // It's a full date, parse it directly
                const endDate = new Date(endPart);
                if (!isNaN(endDate.getTime())) {
                  return {
                    start: startDate,
                    end: endDate,
                    allDay: false
                  };
                }
              }
            }
            
            // If we can't parse the end part, just use the start date
            return {
              start: startDate,
              end: null,
              allDay: false
            };
          }
          
          // Fallback - try direct parsing
          const date = new Date(dateStr);
          if (!isNaN(date.getTime())) {
            return {
              start: date,
              end: null,
              allDay: false
            };
          }
          
          console.warn('Unable to parse date with direct approach:', dateStr);
          return null;
          
        } catch (error) {
          console.error('Error parsing date:', dateStr, error);
          return null;
        }
      }).filter(Boolean); // Remove any failed parses
      
    } catch (error) {
      console.error('Error processing date string:', dateString, error);
      return [];
    }
  }

  /**
   * Get the full image URL from a relative path
   * @param {string} imagePath - Relative image path
   * @returns {string} Full URL for the image
   */
  getImageUrl(imagePath) {
    if (!imagePath) {
      return '/app/event-placeholder.svg'; // Placeholder SVG
    }
    
    // If the image path already starts with http, it's already a full URL
    if (imagePath.startsWith('http')) {
      return imagePath;
    }
    
    // Otherwise, it's a relative path, so append it to the base URL
    return `https://www.viterbo.edu${imagePath}`;
  }

  /**
   * Get a limited number of upcoming events for the carousel
   * @param {number} limit - Maximum number of events to return
   * @returns {Promise<Array>} Array of upcoming events
   */
  async getUpcomingEvents(limit = 3) {
    const events = await this.fetchEvents();
    return events.slice(0, limit);
  }

  /**
   * Get event by title (since we don't have a unique ID in the sample data)
   * @param {string} title - Title of the event to find
   * @returns {Promise<Object>} Event object or null if not found
   */
  async getEventByTitle(title) {
    const events = await this.fetchEvents();
    return events.find(event => event.title === title) || null;
  }
}

const eventsService = new EventsService();
export default eventsService;