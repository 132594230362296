// src/components/CourseSchedule.js
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import './CourseSchedule.css';

// SVG Clock Icon component
const ClockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="10"></circle>
    <polyline points="12 6 12 12 16 14"></polyline>
  </svg>
);

// SVG Location Icon component
const LocationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
    <circle cx="12" cy="10" r="3"></circle>
  </svg>
);

// SVG Info Icon component
const InfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="16" x2="12" y2="12"></line>
    <line x1="12" y1="8" x2="12.01" y2="8"></line>
  </svg>
);

const CourseSchedule = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [terms, setTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState('');
  const { user, moodleData, moodleLoading, fetchMoodleData } = useAuth();
  const [dataSource, setDataSource] = useState('moodle'); // 'moodle' or 'api'

  // Format course section name for display
  const formatCourseName = (secName) => {
    // Example: Convert "BIOL-10101-001" to "BIOL 101"
    const parts = secName.split('-');
    if (parts.length >= 2) {
      const subject = parts[0];
      const courseNumber = parts[1].replace(/^0+/, ''); // Remove leading zeros
      return `${subject} ${courseNumber.substring(0, 3)}`;
    }
    return secName;
  };

  // Check if the user has enrolled courses in Moodle
  const hasEnrolledCourses = () => {
    // If there's no Moodle data or no courses array, user has no courses
    if (!moodleData || !moodleData.courses) return false;
    
    // If there are courses but the array is empty, user has no courses
    if (moodleData.courses.length === 0) return false;
    
    // If there's no user data in Moodle, they weren't found
    if (!moodleData.user) return false;
    
    // User has enrolled courses
    return true;
  };

  // Process and combine meeting data from Moodle
  const processMoodleData = (moodleData) => {
    // If user has no enrolled courses, return empty array
    if (!hasEnrolledCourses()) {
      console.log('User has no enrolled courses in Moodle - returning empty array');
      return [];
    }

    if (!moodleData || !moodleData.course_meetings || moodleData.course_meetings.length === 0) {
      return [];
    }

    // Extract unique terms from the meeting data
    const uniqueTerms = [...new Set(moodleData.course_meetings.map(meeting => meeting.sec_term))];
    
    // Only set terms state if it's different from current state
    if (JSON.stringify(terms) !== JSON.stringify(uniqueTerms)) {
      setTerms(uniqueTerms);
      
      // If we have terms and none is selected, use the first one
      if (uniqueTerms.length > 0 && !selectedTerm) {
        setSelectedTerm(uniqueTerms[0]);
      }
    }

    // Filter meetings by selected term AND by the user's enrolled courses
    if (selectedTerm && moodleData.courses && moodleData.courses.length > 0) {
      // Get the IDs of courses the user is enrolled in
      const enrolledCourseIds = moodleData.courses.map(course => course.id);
      
      // Filter meeting data to only include courses the user is enrolled in
      return moodleData.course_meetings
        .filter(meeting => {
          // First filter by term
          if (meeting.sec_term !== selectedTerm) return false;
          
          // Then check if this meeting is for an enrolled course
          return meeting.moodle_course_id && 
                 enrolledCourseIds.includes(meeting.moodle_course_id);
        })
        .map(meeting => ({
          id: meeting.id,
          sec_name: meeting.sec_name,
          sec_term: meeting.sec_term,
          start_date: meeting.start_date,
          end_date: meeting.end_date,
          meeting_days: meeting.meeting_days,
          instruction_method: meeting.instruction_method,
          start_time: meeting.start_time,
          end_time: meeting.end_time,
          location: meeting.location,
          meeting_info: meeting.meeting_info,
          meeting_position: meeting.meeting_position,
        }));
    }
    
    // If no term selected or no courses, return empty array
    return [];
  };

  // Fallback to API if no Moodle data is available
  const fetchCoursesFromAPI = async () => {
    setLoading(true);
    setError(null);
    
    // If user has no enrolled courses, return empty array
    if (!hasEnrolledCourses()) {
      console.log('User has no enrolled courses in Moodle - returning empty array');
      setCourses([]);
      setLoading(false);
      return;
    }
    
    try {
      // Fetch available terms first
      const termsResponse = await fetch('/app/api/terms', {
        credentials: 'include'
      });
      
      if (!termsResponse.ok) {
        throw new Error('Failed to fetch terms');
      }
      
      const termsData = await termsResponse.json();
      const availableTerms = termsData.terms || [];
      setTerms(availableTerms);
      
      // Set default selected term if needed
      let termToUse = selectedTerm;
      if (availableTerms.length > 0 && !selectedTerm) {
        termToUse = availableTerms[0];
        setSelectedTerm(termToUse);
      }
      
      // Now fetch courses for the term
      const termParam = termToUse ? `?term=${encodeURIComponent(termToUse)}` : '';
      const courseResponse = await fetch(`/app/api/courses${termParam}`, {
        credentials: 'include'
      });
      
      if (!courseResponse.ok) {
        throw new Error(`Failed to fetch courses: ${courseResponse.status}`);
      }
      
      const courseData = await courseResponse.json();
      setCourses(courseData || []);
    } catch (err) {
      console.error('Error fetching course data from API:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Effect for handling initial data loading
  useEffect(() => {
    // Create a flag to track if component is still mounted
    let isActive = true;
    
    const loadData = async () => {
      if (dataSource === 'moodle') {
        if (moodleLoading) {
          setLoading(true);
          return;
        }
        
        if (moodleData) {
          // Check if user has enrolled courses
          if (!hasEnrolledCourses()) {
            if (isActive) {
              setCourses([]);
              setLoading(false);
            }
            return;
          }
          
          // Process and load Moodle data automatically
          const processedCourses = processMoodleData(moodleData);
          if (isActive) {
            setCourses(processedCourses);
            setLoading(false);
          }
        } else {
          // If no Moodle data is available, fallback to API
          if (isActive) {
            console.log('No Moodle data available, falling back to API');
            setDataSource('api');
            // Don't call fetchCoursesFromAPI here - it will be triggered by dataSource change
          }
        }
      } else if (dataSource === 'api') {
        fetchCoursesFromAPI();
      }
    };
    
    loadData();
    
    // Cleanup function
    return () => {
      isActive = false;
    };
  }, [moodleData, moodleLoading, dataSource, selectedTerm, user]);

  // Toggle data source (for development only)
  const toggleDataSource = () => {
    if (dataSource === 'moodle') {
      setDataSource('api');
    } else {
      setDataSource('moodle');
    }
  };

  // Group courses by day for display
  const groupCoursesByDay = (courses) => {
    const groupedCourses = {};
    
    // Define day order for sorting
    const dayOrder = {
      'Mon': 1,
      'Tues': 2,
      'Wed': 3,
      'Thurs': 4,
      'Fri': 5,
      'Sat': 6,
      'Sun': 7,
      'Internship': 8,
      'Practicum': 9,
      'On-Line': 10,
      'Independent Study': 11,
      'Research': 12,
      'Studio': 13,
      'Clinical': 14,
      'Travel': 15,
      'Lessons': 16,
      'Blended (on-Line, Lecture)': 17,
      'Laboratory': 18,
      'Lecture': 19,
      'Other': 20
    };

    courses.forEach(course => {
      // Handle multiple meeting days (e.g., "Mon Wed Fri")
      const days = course.meeting_days ? course.meeting_days.split(' ') : ['Unspecified'];
      
      days.forEach(day => {
        if (!groupedCourses[day]) {
          groupedCourses[day] = [];
        }
        groupedCourses[day].push(course);
      });
    });

    // Sort the days according to our custom order
    const sortedDays = Object.keys(groupedCourses).sort((a, b) => {
      return (dayOrder[a] || 99) - (dayOrder[b] || 99);
    });

    // For each day, sort the courses by start time
    sortedDays.forEach(day => {
      groupedCourses[day].sort((a, b) => {
        // Convert start times to comparable format
        const timeA = a.start_time ? a.start_time.replace(/[^0-9:]/g, '') : '0000';
        const timeB = b.start_time ? b.start_time.replace(/[^0-9:]/g, '') : '0000';
        return timeA.localeCompare(timeB);
      });
    });

    return { groupedCourses, sortedDays };
  };

  // Set up grouped courses for display
  const { groupedCourses, sortedDays } = groupCoursesByDay(courses);

  if (loading) {
    return (
      <div className="schedule-loading">
        <div className="loading-spinner"></div>
        <p>Loading your course schedule...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="schedule-error">
        <h3>Error Loading Schedule</h3>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="course-schedule">
      <div className="schedule-header">
        <h2>Course Schedule</h2>
        
        {selectedTerm && (
          <div className="term-display">
            <span className="term-label">Term: {selectedTerm}</span>
          </div>
        )}
        
        {process.env.NODE_ENV === 'development' && (
          <div className="data-source-toggle">
            <button 
              onClick={toggleDataSource}
              className="btn-toggle-source"
            >
              {dataSource === 'moodle' ? 'Using Moodle Data' : 'Using API Data'}
            </button>
          </div>
        )}
      </div>
      
      {courses.length === 0 ? (
        <div className="no-courses">
          <p>No courses found for the current term.</p>
        </div>
      ) : (
        <div className="schedule-days">
          {sortedDays.map(day => (
            <div key={day} className="schedule-day">
              <h3 className="day-header">{day}</h3>
              <div className="day-courses">
                {groupedCourses[day].map((course, index) => (
                  <div key={`${course.sec_name}-${course.meeting_position || index}`} className="course-card">
                    <div className="course-header">
                      <h4 className="course-name">{formatCourseName(course.sec_name)}</h4>
                      {/*course.meeting_position && (
                        <span className="course-type">{course.meeting_position}</span>
                      )*/}
                    </div>
                    
                    <div className="course-details">
                      <div className="course-time">
                        <ClockIcon />
                        <span>{course.start_time || 'TBA'} - {course.end_time || 'TBA'}</span>
                      </div>
                      
                      <div className="course-location">
                        <LocationIcon />
                        <span>{course.location || 'Location TBA'}</span>
                      </div>
                      
                      {course.instruction_method && (
                        <div className="course-method">
                          <InfoIcon />
                          <span>{course.instruction_method}</span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CourseSchedule;