import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

console.log('React initialization starting');

const rootElement = document.getElementById('root');
console.log('Root element found:', rootElement);

try {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
  console.log('React render completed');
} catch (error) {
  console.error('Error rendering React app:', error);
  // Display error visually
  if (rootElement) {
    rootElement.innerHTML = `
      <div style="padding: 20px; color: red;">
        <h1>React Error</h1>
        <pre>${error.message}</pre>
      </div>
    `;
  }
}