// src/pages/EventsPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import eventsService from '../services/EventsService';
import HamburgerMenu from '../components/HamburgerMenu';
import { useAuth } from '../context/AuthContext';
import './EventsPage.css';

// Calendar icon component 
const CalendarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="16" y1="2" x2="16" y2="6"></line>
    <line x1="8" y1="2" x2="8" y2="6"></line>
    <line x1="3" y1="10" x2="21" y2="10"></line>
  </svg>
);

// Clock icon component
const ClockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="10"></circle>
    <polyline points="12 6 12 12 16 14"></polyline>
  </svg>
);

// Back arrow icon component
const BackArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M19 12H5M12 19l-7-7 7-7"/>
  </svg>
);

// Refresh icon component
const RefreshIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="23 4 23 10 17 10"></polyline>
    <polyline points="1 20 1 14 7 14"></polyline>
    <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
  </svg>
);

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshing, setRefreshing] = useState(false);
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    let mounted = true;
    
    const loadEvents = async () => {
      try {
        setLoading(true);
        const fetchedEvents = await eventsService.fetchEvents();
        
        if (mounted) {
          setEvents(fetchedEvents);
          setLoading(false);
        }
      } catch (err) {
        console.error('Error fetching events:', err);
        if (mounted) {
          setError('Unable to load events at this time');
          setLoading(false);
        }
      }
    };
    
    loadEvents();
    
    return () => {
      mounted = false;
    };
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleEventClick = (event) => {
    navigate(`/events/${encodeURIComponent(event.title)}`);
  };

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    try {
      const refreshedEvents = await eventsService.fetchEvents();
      setEvents(refreshedEvents);
      setError(null);
    } catch (err) {
      console.error('Error refreshing events:', err);
      setError('Unable to refresh events at this time');
    }
    setRefreshing(false);
  };

  const groupEventsByMonth = (events) => {
    const grouped = {};
    
    events.forEach(event => {
      if (!event.firstDate) return;
      
      const date = new Date(event.firstDate);
      const monthYear = date.toLocaleDateString('en-US', {
        month: 'long',
        year: 'numeric'
      });
      
      if (!grouped[monthYear]) {
        grouped[monthYear] = [];
      }
      
      grouped[monthYear].push(event);
    });
    
    return grouped;
  };

  const groupedEvents = groupEventsByMonth(events);
  const monthKeys = Object.keys(groupedEvents).sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA - dateB;
  });

  return (
    <div className="events-page">
      <header className="dashboard-header">
        <div className="dashboard-brand">
          <button 
            className="back-button" 
            onClick={handleBackClick}
            aria-label="Go back to dashboard"
          >
            <BackArrowIcon />
          </button>
          <h1>Campus Events</h1>
        </div>
        <div className="dashboard-header-right">
          <button
            className="btn-refresh"
            onClick={handleRefresh}
            disabled={loading || refreshing}
            aria-label="Refresh events"
          >
            {refreshing ? (
              <div className="loading-spinner-small"></div>
            ) : (
              <RefreshIcon />
            )}
          </button>
          <HamburgerMenu onLogout={handleLogout} />
        </div>
      </header>

      <div className="events-content">
        {loading ? (
          <div className="events-loading">
            <div className="loading-spinner"></div>
            <p>Loading events...</p>
          </div>
        ) : error ? (
          <div className="events-error">
            <p>{error}</p>
            <button className="btn-retry" onClick={handleRefresh}>
              Try Again
            </button>
          </div>
        ) : events.length === 0 ? (
          <div className="events-empty">
            <CalendarIcon />
            <p>No upcoming events found</p>
          </div>
        ) : (
          <div className="events-list-container">
            {monthKeys.map(month => (
              <div key={month} className="events-month-group">
                <h2 className="month-header">{month}</h2>
                <div className="events-list">
                  {groupedEvents[month].map((event, index) => (
                    <div 
                      key={index}
                      className="event-card"
                      onClick={() => handleEventClick(event)}
                    >
                      <div className="event-card-image">
                        <img 
                          src={event.imageUrl} 
                          alt={event.title}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/app/RGB_Red_Icon.png';
                          }}
                        />
                      </div>
                      <div className="event-card-content">
                        <h3 className="event-card-title">{event.title}</h3>
                        <div className="event-card-date">
                          <ClockIcon />
                          <span>{event.formattedDate}</span>
                        </div>
                        {event.body && (
                          <p className="event-card-description">
                            {event.body.length > 120 
                              ? `${event.body.substring(0, 120)}...` 
                              : event.body}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EventsPage;