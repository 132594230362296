// src/components/HamburgerMenu.js 
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './HamburgerMenu.css';

const HamburgerMenu = ({ onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const { user } = useAuth();

  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  // Close the menu when ESC key is pressed
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    setIsOpen(false);
    if (onLogout) {
      onLogout();
    }
  };

  return (
    <div className="hamburger-menu-container" ref={menuRef}>
      <button 
        className={`hamburger-button ${isOpen ? 'open' : ''}`} 
        onClick={toggleMenu}
        aria-label="Menu"
        aria-expanded={isOpen}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
      
      {isOpen && (
        <div className="menu-overlay" onClick={() => setIsOpen(false)}></div>
      )}

      <div className={`menu-content ${isOpen ? 'open' : ''}`}>
        <div className="menu-header">
          <img src="/app/RGB_Red_Icon.png" alt="Viterbo University Logo" className="menu-logo" />
          <h2>Viterbo University</h2>
          <button 
            className="close-menu-button" 
            onClick={() => setIsOpen(false)}
            aria-label="Close menu"
          >
            {/* SVG with explicit colors for iOS compatibility */}
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="28" 
              height="28" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="#FFFFFF" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        
        {user && (
          <div className="menu-user-info">
            <div className="user-email">{user.email || user.name || 'User'}</div>
          </div>
        )}
        
        <nav className="menu-nav">
          <Link to="/dashboard" onClick={() => setIsOpen(false)}>Dashboard</Link>
          <Link to="/self-service" onClick={() => setIsOpen(false)}>Self-Service</Link>
          <Link to="/moodle" onClick={() => setIsOpen(false)}>Moodle</Link>
          <Link to="/events" onClick={() => setIsOpen(false)}>Campus Events</Link>
          <Link to="/myvu" onClick={() => setIsOpen(false)}>MyVU</Link>
          <Link to="/cafeteria" onClick={() => setIsOpen(false)}>The Caf</Link>
          <Link to="/user-details" onClick={() => setIsOpen(false)}>Account</Link>
        </nav>
        
        <div className="menu-footer">
          <button className="menu-logout-button" onClick={handleLogout}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
              <polyline points="16 17 21 12 16 7"></polyline>
              <line x1="21" y1="12" x2="9" y2="12"></line>
            </svg>
            Logout
          </button>
          <p>Viterbo University &copy; {new Date().getFullYear()}</p>
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenu;