// UserDetails.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import HamburgerMenu from './HamburgerMenu';

const UserDetails = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { user, refreshUser, moodleData, fetchMoodleData } = useAuth();
  const navigate = useNavigate();

  // Run once on component mount
  useEffect(() => {
    let isMounted = true;
    
    const loadData = async () => {
      try {
        // Start with existing data
        if (user) {
          setLoading(false);
        }
        
        // Try to refresh user data
        await refreshUser();
        
        // Only update state if component is still mounted
        if (isMounted) {
          setLoading(false);
        }
      } catch (err) {
        console.error("Error loading user data:", err);
        if (isMounted) {
          setError("Could not load user information");
          setLoading(false);
        }
      }
    };
    
    loadData();
    
    // Cleanup
    return () => {
      isMounted = false;
    };
  }, []); // Empty dependency array - run once on mount only

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('moodleData');
    navigate('/');
  };

  const handleGoBack = () => {
    navigate('/dashboard');
  };

  // Get the best available user information
  const getUserInfo = () => {
    // Start with our basic user data
    let userInfo = {
      displayName: '',
      firstName: '',
      lastName: '',
      email: '',
      accountType: ''
    };
    
    // Add data from the user state
    if (user) {
      userInfo = {
        ...userInfo,
        ...user
      };
      
      // Try to split displayName into first and last name if not already available
      if (!userInfo.firstName && userInfo.displayName) {
        const nameParts = userInfo.displayName.split(' ');
        if (nameParts.length >= 2) {
          userInfo.firstName = nameParts[0];
          userInfo.lastName = nameParts.slice(1).join(' ');
        } else {
          userInfo.firstName = userInfo.displayName;
        }
      }
    }
    
    // Add Moodle data if available
    if (moodleData && moodleData.user) {
      const mUser = moodleData.user;
      
      // Update with Moodle data if not already set
      userInfo = {
        ...userInfo,
        displayName: userInfo.displayName || mUser.fullname,
        email: userInfo.email || mUser.email,
        profileImage: mUser.profileimageurl
      };
      
      // Try to get first/last name from Moodle data if not already set
      if ((!userInfo.firstName || !userInfo.lastName) && mUser.fullname) {
        const nameParts = mUser.fullname.split(' ');
        if (nameParts.length >= 2) {
          userInfo.firstName = userInfo.firstName || nameParts[0];
          userInfo.lastName = userInfo.lastName || nameParts.slice(1).join(' ');
        } else {
          userInfo.firstName = userInfo.firstName || mUser.fullname;
        }
      }
      
      // Use specific Moodle fields if available
      if (mUser.firstname) {
        userInfo.firstName = userInfo.firstName || mUser.firstname;
      }
      
      if (mUser.lastname) {
        userInfo.lastName = userInfo.lastName || mUser.lastname;
      }
    }
    
    // Determine account type
    userInfo.accountType = process.env.NODE_ENV === 'development' && userInfo.developerAccess 
      ? 'Development Account' 
      : 'Student Account';
    
    return userInfo;
  };

  // Get course count from Moodle data
  const getCourseCount = () => {
    if (moodleData && moodleData.courses) {
      return moodleData.courses.length;
    }
    return 0;
  };

  const userInfo = getUserInfo();

  if (loading) {
    return (
      <div className="dashboard">
        <header className="dashboard-header">
          <div className="dashboard-brand">
            <div className="dashboard-logo">
              <img src="/app/RGB_Red_Icon.png" alt="Viterbo Logo" />
            </div>
            <h1>User Details</h1>
          </div>
          <div className="dashboard-header-right">
            <HamburgerMenu onLogout={handleLogout} />
          </div>
        </header>
        <div className="dashboard-content" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <div className="loading-spinner"></div>
            <p>Loading user details...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <div className="dashboard-brand">
          <div className="dashboard-logo">
            <img src="/app/RGB_Red_Icon.png" alt="Viterbo Logo" />
          </div>
          <h1>User Details</h1>
        </div>
        <div className="dashboard-header-right">
          <HamburgerMenu onLogout={handleLogout} />
        </div>
      </header>

      <div className="dashboard-content">
        <div className="dashboard-card" style={{ maxWidth: '600px', margin: '0 auto' }}>
          <h2>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
            Account Information
          </h2>

          {/* Show Moodle profile image if available */}
          {userInfo.profileImage && (
            <div className="user-profile-image">
              <img 
                src={userInfo.profileImage} 
                alt={`${userInfo.displayName || 'User'}'s profile`}
              />
            </div>
          )}

          <div className="user-details-grid">
            {userInfo.firstName && (
              <div className="user-details-item">
                <span className="user-details-label">First Name</span>
                <span className="user-details-value">{userInfo.firstName}</span>
              </div>
            )}
            
            {userInfo.lastName && (
              <div className="user-details-item">
                <span className="user-details-label">Last Name</span>
                <span className="user-details-value">{userInfo.lastName}</span>
              </div>
            )}
            
            {userInfo.email && (
              <div className="user-details-item">
                <span className="user-details-label">Email</span>
                <span className="user-details-value">{userInfo.email}</span>
              </div>
            )}
            
            <div className="user-details-item">
              <span className="user-details-label">Account Type</span>
              <span className="user-details-value">{userInfo.accountType}</span>
            </div>
          </div>
          
          {/* Show Moodle course summary if available */}
          {getCourseCount() > 0 && (
            <div className="user-courses-section">
              <h3>Enrolled Courses</h3>
              <p>You are currently enrolled in {getCourseCount()} course(s).</p>
            </div>
          )}

          {error && (
            <div className="error-message" style={{ 
              backgroundColor: "rgba(158, 27, 52, 0.1)", 
              color: "#9E1B34", 
              padding: "12px", 
              borderRadius: "8px", 
              margin: "16px 0" 
            }}>
              {error}
            </div>
          )}

          <button 
            className="btn-sso" 
            onClick={handleGoBack}
            style={{ marginTop: '20px' }}
          >
            Back to Dashboard
          </button>
        </div>
      </div>

      <style jsx>{`
        .user-details-grid {
          display: grid;
          grid-template-columns: 1fr;
          gap: 16px;
          margin-top: 16px;
        }
        
        .user-details-item {
          padding: 16px;
          background-color: var(--viterbo-off-white);
          border-radius: var(--border-radius-md);
          box-shadow: var(--shadow-sm);
        }
        
        .user-details-label {
          display: block;
          font-size: var(--font-size-sm);
          color: var(--viterbo-gray);
          margin-bottom: 4px;
        }
        
        .user-details-value {
          display: block;
          font-size: var(--font-size-lg);
          font-weight: var(--font-weight-medium);
          color: var(--viterbo-blue);
        }
        
        .user-profile-image {
          display: flex;
          justify-content: center;
          margin-bottom: 24px;
        }
        
        .user-profile-image img {
          border-radius: 50%;
          width: 150px;
          height: 150px;
          object-fit: cover;
          border: 3px solid var(--viterbo-white);
          box-shadow: var(--shadow-md);
        }
        
        .user-courses-section {
          margin-top: 24px;
          padding-top: 24px;
          border-top: 1px solid var(--viterbo-light-gray);
        }
        
        .user-courses-section h3 {
          font-size: var(--font-size-lg);
          color: var(--viterbo-blue);
          margin-bottom: 16px;
        }
        
        .btn-view-courses {
          background-color: var(--viterbo-blue);
          color: var(--viterbo-white);
          padding: var(--spacing-sm) var(--spacing-md);
          border: none;
          border-radius: var(--border-radius-md);
          font-size: var(--font-size-sm);
          cursor: pointer;
          margin-top: var(--spacing-sm);
          transition: background-color var(--transition-normal);
        }
        
        .btn-view-courses:hover {
          background-color: var(--viterbo-blue-light);
        }
        
        @media (min-width: 768px) {
          .user-details-grid {
            grid-template-columns: 1fr 1fr;
          }
        }
      `}</style>
    </div>
  );
};

export default UserDetails;