// src/components/DesktopNav.js
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './DesktopNav.css';

const DesktopNav = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Check if the current route matches the nav item
  const isActive = (path) => {
    return location.pathname === path;
  };
  
  // Function to scroll to top when on dashboard
  const handleHomeClick = (e) => {
    // If already on dashboard, prevent default link behavior and scroll to top
    if (location.pathname === '/dashboard') {
      e.preventDefault();
      
      // Smooth scroll to top
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      
      // Fallbacks for older browsers
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  };
  
  return (
    <div className="desktop-nav">
      <Link 
        to="/dashboard" 
        className={`desktop-nav-item ${isActive('/dashboard') ? 'active' : ''}`}
        onClick={handleHomeClick}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
          <polyline points="9 22 9 12 15 12 15 22"></polyline>
        </svg>
        <span>Home</span>
      </Link>
      
      <Link 
        to="/self-service" 
        className={`desktop-nav-item ${isActive('/self-service') ? 'active' : ''}`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
          <circle cx="12" cy="7" r="4"></circle>
        </svg>
        <span>Self-Service</span>
      </Link>
      
      <Link 
        to="/moodle" 
        className={`desktop-nav-item ${isActive('/moodle') ? 'active' : ''}`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
          <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
        </svg>
        <span>Moodle</span>
      </Link>
      
      <Link 
        to="/events" 
        className={`desktop-nav-item ${isActive('/events') ? 'active' : ''}`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
          <line x1="16" y1="2" x2="16" y2="6"></line>
          <line x1="8" y1="2" x2="8" y2="6"></line>
          <line x1="3" y1="10" x2="21" y2="10"></line>
        </svg>
        <span>Events</span>
      </Link>
      
      <Link 
        to="/myvu" 
        className={`desktop-nav-item ${isActive('/myvu') ? 'active' : ''}`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
          <line x1="8" y1="21" x2="16" y2="21"></line>
          <line x1="12" y1="17" x2="12" y2="21"></line>
        </svg>
        <span>MyVU</span>
      </Link>
      
      <Link 
        to="/cafeteria" 
        className={`desktop-nav-item ${isActive('/cafeteria') ? 'active' : ''}`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M18 8h1a4 4 0 0 1 0 8h-1"></path>
          <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path>
          <line x1="6" y1="1" x2="6" y2="4"></line>
          <line x1="10" y1="1" x2="10" y2="4"></line>
          <line x1="14" y1="1" x2="14" y2="4"></line>
        </svg>
        <span>The Caf</span>
      </Link>
    </div>
  );
};

export default DesktopNav;