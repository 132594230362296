// src/components/ProtectedRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { user, loading, refreshUser } = useAuth();
  const location = useLocation();
  const [initialCheckDone, setInitialCheckDone] = useState(false);

  // Handle authentication verification
  useEffect(() => {
    // Only refresh on initial mount
    if (!initialCheckDone) {
      refreshUser().finally(() => {
        setInitialCheckDone(true);
      });
    }
  }, [refreshUser, initialCheckDone]);

  // Check if we have a developer bypass user in localStorage
  // This helps in development mode when the backend API is not available
  useEffect(() => {
    if (!user && process.env.NODE_ENV === 'development') {
      const localUser = localStorage.getItem('user');
      if (localUser) {
        const parsedUser = JSON.parse(localUser);
        if (parsedUser.developerAccess) {
          console.log('Development mode: Using developer bypass user');
          setInitialCheckDone(true);
        }
      }
    }
  }, [user]);

  // Show loading spinner while checking authentication
  if (loading && !initialCheckDone) {
    return (
      <div className="signin-container">
        <div className="signin-card" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="loading-spinner" style={{ width: '30px', height: '30px', marginBottom: '1rem' }}></div>
          <p>Verifying authentication...</p>
        </div>
      </div>
    );
  }

  // Special handling for development mode
  if (process.env.NODE_ENV === 'development') {
    const localUser = localStorage.getItem('user');
    if (localUser) {
      const parsedUser = JSON.parse(localUser);
      if (parsedUser.developerAccess) {
        return children;
      }
    }
  }

  // Redirect to login if no user
  if (!user) {
    // Save the location they were trying to go to
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // If authentication is successful, render the protected component
  return children;
};

export default ProtectedRoute;