// InstallButton.js 
import React, { useEffect, useState } from 'react';
import './InstallButton.css';

const InstallButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstalled, setIsInstalled] = useState(false);
  const [isInstallable, setIsInstallable] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const [showIOSInstructions, setShowIOSInstructions] = useState(false);
  const [statusChecked, setStatusChecked] = useState(false);

  useEffect(() => {
    // Flag to prevent state updates after unmount
    let isMounted = true;
    
    // Check if running on iOS
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIOSDevice = /iphone|ipad|ipod/.test(userAgent);
    if (isMounted) setIsIOS(isIOSDevice);

    // Check if running on Android
    const isAndroidDevice = /android/.test(userAgent);
    if (isMounted) setIsAndroid(isAndroidDevice);

    // Check if running in standalone mode (already installed)
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches 
      || window.navigator.standalone 
      || document.referrer.includes('android-app://');
    
    if (isStandalone && isMounted) {
      console.log('App is running in standalone mode (installed)');
      setIsInstalled(true);
      localStorage.setItem('appInstalled', 'true');
      setStatusChecked(true);
      return; // No need to continue if already installed
    }
    
    // Check stored installation status
    const storedInstallStatus = localStorage.getItem('appInstalled') === 'true';
    if (storedInstallStatus && isMounted) {
      setIsInstalled(true);
      setStatusChecked(true);
      return; // No need to continue if we know it's installed
    }

    // Handle installation prompt
    const handleBeforeInstallPrompt = (e) => {
      // Prevent browser from showing the default prompt
      e.preventDefault();
      
      if (isMounted) {
        console.log('Install prompt event captured');
        // Store the event for later use
        setDeferredPrompt(e);
        setIsInstallable(true);
        setStatusChecked(true);
      }
    };

    // Handle successful installation
    const handleAppInstalled = () => {
      if (isMounted) {
        console.log('App was installed successfully');
        setIsInstalled(true);
        setIsInstallable(false);
        setDeferredPrompt(null);
        localStorage.setItem('appInstalled', 'true');
        setStatusChecked(true);
      }
    };

    // Add event listeners
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', handleAppInstalled);

    // For iOS/Safari, just mark as installable if conditions are met
    if (isIOSDevice && isMounted) {
      console.log('iOS device detected, marking as installable');
      setIsInstallable(true);
      setStatusChecked(true);
    }

    // Set a timeout to ensure the status check completes
    // If we haven't determined status after timeout, assume 
    // the app is either not installable or the browser doesn't support it
    const timeoutId = setTimeout(() => {
      if (isMounted && !statusChecked) {
        console.log('Install prompt not received, setting default status');
        
        // For Android devices, we can assume installable if the prompt hasn't fired
        // (might be due to user already dismissing it)
        if (isAndroidDevice) {
          setIsInstallable(true);
        } else {
          // For other browsers, check basic PWA criteria
          const isPWA = !!navigator.serviceWorker && 
                        !!window.matchMedia && 
                        !!window.Notification;
          
          setIsInstallable(isPWA);
        }
        
        setStatusChecked(true);
      }
    }, 2000);

    // Cleanup function
    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, [statusChecked]);

  const handleInstallClick = async (e) => {
    // Prevent any default behavior and stop event propagation
    e.preventDefault();
    e.stopPropagation();

    if (isIOS) {
      setShowIOSInstructions(true);
      return;
    }

    if (!deferredPrompt) {
      console.log('No install prompt available');
      // Try refreshing the page to get a new prompt
      window.location.reload();
      return;
    }

    try {
      // Show the install prompt
      deferredPrompt.prompt();
      
      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.userChoice;
      console.log(`User ${outcome} the installation`);
      
      // Clear the saved prompt since it can only be used once
      setDeferredPrompt(null);
      
      if (outcome === 'accepted') {
        setIsInstalled(true);
        setIsInstallable(false);
        localStorage.setItem('appInstalled', 'true');
      }
    } catch (error) {
      console.error('Error during installation:', error);
    }
  };

  // Close the modal when clicking outside content area
  const handleModalBackdropClick = (e) => {
    if (e.target.className === 'ios-modal-backdrop') {
      setShowIOSInstructions(false);
    }
  };

  // Don't show if already installed
  if (isInstalled) return null;

  // Install icon SVG
  const InstallIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
      <polyline points="7 10 12 15 17 10"></polyline>
      <line x1="12" y1="15" x2="12" y2="3"></line>
    </svg>
  );

  return (
    <div onClick={(e) => e.stopPropagation()} className="install-button-container">
      <button
        onClick={handleInstallClick}
        className="btn-install"
        disabled={!isInstallable && !statusChecked}
        aria-label="Install application"
      >
        <InstallIcon />
        {!statusChecked ? 'Checking status...' : 
          isIOS ? 'Add to Home Screen' : 
          'Install App'}
      </button>
      
      {showIOSInstructions && isIOS && (
        <div className="ios-modal-backdrop" onClick={handleModalBackdropClick}>
          <div className="ios-modal">
            <div className="ios-modal-header">
              <h3>Install this App on your iPhone</h3>
              <button 
                className="ios-modal-close"
                onClick={() => setShowIOSInstructions(false)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>
            
            <div className="ios-modal-body">
              <div className="ios-instructions-steps">
                <div className="ios-step">
                  <div className="ios-step-number">1</div>
                  <div className="ios-step-content">
                    <p>Tap the <strong>Share button</strong></p>
                    <div className="ios-share-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
                        <polyline points="16 6 12 2 8 6"></polyline>
                        <line x1="12" y1="2" x2="12" y2="15"></line>
                      </svg>
                    </div>
                  </div>
                </div>
                
                <div className="ios-step">
                  <div className="ios-step-number">2</div>
                  <div className="ios-step-content">
                    <p>Scroll down and tap <strong>"Add to Home Screen"</strong></p>
                    <div className="ios-add-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                        <line x1="12" y1="8" x2="12" y2="16"></line>
                        <line x1="8" y1="12" x2="16" y2="12"></line>
                      </svg>
                    </div>
                  </div>
                </div>
                
                <div className="ios-step">
                  <div className="ios-step-number">3</div>
                  <div className="ios-step-content">
                    <p>Tap <strong>"Add"</strong> in the top right corner</p>
                    <div className="ios-confirm-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="ios-modal-footer">
              <button 
                className="ios-modal-button"
                onClick={() => setShowIOSInstructions(false)}
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InstallButton;