// src/App.js 
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignInPage from './components/SignInPage';
import Dashboard from './pages/Dashboard';
import UserDetails from './components/UserDetails';
import MoodleIntegration from './components/MoodleIntegration';
import UserCourseRegistration from './components/UserCourseRegistration';
import EventsPage from './pages/EventsPage';
import EventDetailPage from './pages/EventDetailpage';
import ProtectedRoute from './components/ProtectedRoute';
import IFrameWrapper from './components/IFrameWrapper';
import ExternalRedirect from './components/ExternalRedirect';
import { AuthProvider } from './context/AuthContext';
import './styles/designSystem.css';

// Development debugging
const isDev = process.env.NODE_ENV === 'development';
if (isDev) {
  console.log('Running in DEVELOPMENT mode');
}

// External site URLs
const SELF_SERVICE_URL = 'https://clgssprod.exchange.viterbo.edu/Student/Account/Login?ReturnUrl=%2FStudent%2F%3FhideProxyDialog%3DFalse';
const MYVU_URL = 'https://www.viterbo.edu/myvu';
const MOODLE_URL = 'https://moodle.viterbo.edu/local/login/index.php';
const CAFETERIA_URL = 'https://viterbo.campusdish.com/en/locationsandmenus/thecaf/';

function App() {
  // Register service worker for PWA
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker.register('/app/service-worker.js')
          .then(registration => {
            console.log('Service Worker registered with scope:', registration.scope);
          })
          .catch(error => {
            console.error('Service Worker registration failed:', error);
          });
      });
    }
  }, []);

  return (
    <AuthProvider>
      <Router basename="/app">
        <div style={{ minHeight: '100vh' }}>
          <Routes>
            <Route path="/" element={<SignInPage />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user-details"
              element={
                <ProtectedRoute>
                  <UserDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path="/moodle"
              element={
                <ProtectedRoute>
                  <ExternalRedirect 
                    url={MOODLE_URL} 
                    title="Moodle" 
                    description="Access your course materials, assignments, and grades on Viterbo's Moodle learning management system."
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/self-service"
              element={
                <ProtectedRoute>
                  <ExternalRedirect 
                    url={SELF_SERVICE_URL} 
                    title="Self-Service" 
                    description="Access your student account, course registration, financial information, and academic records."
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/cafeteria"
              element={
                <ProtectedRoute>
                  <ExternalRedirect 
                    url={CAFETERIA_URL} 
                    title="The Caf" 
                    description="View today's dining options and meal schedules at Viterbo's cafeteria."
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/myvu"
              element={
                <ProtectedRoute>
                  <ExternalRedirect 
                    url={MYVU_URL} 
                    title="MyVU" 
                    description="Access Viterbo University's portal for campus resources, news, events, and important information."
                  />
                </ProtectedRoute>
              }
            />
            
            {/* New Routes for Events Feature */}
            <Route
              path="/events"
              element={
                <ProtectedRoute>
                  <EventsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/events/:eventTitle"
              element={
                <ProtectedRoute>
                  <EventDetailPage />
                </ProtectedRoute>
              }
            />
            
            {/* Catch-all route */}
            <Route
              path="*"
              element={
                <div className="signin-container">
                  <div className="signin-card">
                    <h1>404 - Not Found</h1>
                    <p>Path: {window.location.pathname}</p>
                    <button 
                      className="btn-sso" 
                      onClick={() => window.history.back()}
                      style={{ marginTop: '20px' }}
                    >
                      Go Back
                    </button>
                  </div>
                </div>
              }
            />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;