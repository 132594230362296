// src/pages/EventDetailPage.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import eventsService from '../services/EventsService';
import HamburgerMenu from '../components/HamburgerMenu';
import { useAuth } from '../context/AuthContext';
import './EventDetailPage.css';

// Clock icon component
const ClockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="10"></circle>
    <polyline points="12 6 12 12 16 14"></polyline>
  </svg>
);

// Calendar icon component
const CalendarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="16" y1="2" x2="16" y2="6"></line>
    <line x1="8" y1="2" x2="8" y2="6"></line>
    <line x1="3" y1="10" x2="21" y2="10"></line>
  </svg>
);

// Location icon component
const LocationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
    <circle cx="12" cy="10" r="3"></circle>
  </svg>
);

// Back arrow icon component
const BackArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M19 12H5M12 19l-7-7 7-7"/>
  </svg>
);

// Share icon component
const ShareIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="18" cy="5" r="3"></circle>
    <circle cx="6" cy="12" r="3"></circle>
    <circle cx="18" cy="19" r="3"></circle>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
  </svg>
);

// Link icon component
const LinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
  </svg>
);

const EventDetailPage = () => {
  const { eventTitle } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [showShareOptions, setShowShareOptions] = useState(false);
  
  useEffect(() => {
    let mounted = true;
    
    const fetchEventDetails = async () => {
      try {
        setLoading(true);
        const decodedTitle = decodeURIComponent(eventTitle);
        const eventData = await eventsService.getEventByTitle(decodedTitle);
        
        if (!eventData && mounted) {
          setError('Event not found');
          setLoading(false);
          return;
        }
        
        if (mounted) {
          setEvent(eventData);
          setLoading(false);
        }
      } catch (err) {
        console.error('Error fetching event details:', err);
        if (mounted) {
          setError('Unable to load event details');
          setLoading(false);
        }
      }
    };
    
    fetchEventDetails();
    
    return () => {
      mounted = false;
    };
  }, [eventTitle]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleBackClick = () => {
    navigate(-1); // Go back to previous page
  };

  const handleShare = () => {
    setShowShareOptions(!showShareOptions);
  };

  const shareEvent = async (platform) => {
    const eventUrl = window.location.href;
    const shareText = `Check out this event: ${event.title}`;
    
    switch (platform) {
      case 'clipboard':
        try {
          await navigator.clipboard.writeText(eventUrl);
          alert('Link copied to clipboard!');
        } catch (err) {
          console.error('Failed to copy link:', err);
          // Fallback for browsers that don't support clipboard API
          const textArea = document.createElement('textarea');
          textArea.value = eventUrl;
          document.body.appendChild(textArea);
          textArea.select();
          document.execCommand('copy');
          document.body.removeChild(textArea);
          alert('Link copied to clipboard!');
        }
        break;
      case 'email':
        window.location.href = `mailto:?subject=${encodeURIComponent(shareText)}&body=${encodeURIComponent(eventUrl)}`;
        break;
      default:
        // Default to copying to clipboard
        try {
          await navigator.clipboard.writeText(eventUrl);
          alert('Link copied to clipboard!');
        } catch (err) {
          console.error('Failed to copy link:', err);
        }
    }
    
    setShowShareOptions(false);
  };

  // Extract links from body text
  const extractLinks = (text) => { 
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const links = text.match(urlRegex);
    return links || [];
  };

   // Format links in body text
   const formatBodyWithLinks = (body) => {
    if (!body) return '';
    
    const links = extractLinks(body);
    let formattedBody = body;
    
    links.forEach(link => {
      // Create a clean URL for the href that doesn't have trailing punctuation
      const cleanLink = link.replace(/[.,;:!?)]+$/, '');
      
      formattedBody = formattedBody.replace(
        link,
        `<a href="${cleanLink}" target="_blank" rel="noopener noreferrer" class="event-link">${link}</a>`
      );
    });
    
    return formattedBody;
  };

  // Format multiple dates
  const formatDates = (dates) => {
    if (!dates || dates.length === 0) return null;
    
    return dates.map((dateObj, index) => {
      const date = new Date(dateObj.start);
      const formattedDate = date.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });
      
      let formattedTime = '';
      if (!isNaN(date.getTime())) {
        formattedTime = date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        });
      }
      
      let endTime = '';
      if (dateObj.end) {
        const end = new Date(dateObj.end);
        endTime = end.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        });
      }
      
      return (
        <div key={index} className="event-date-item">
          <CalendarIcon />
          <div className="event-date-details">
            <span className="event-date-day">{formattedDate}</span>
            {formattedTime && (
              <span className="event-date-time">
                {endTime ? `${formattedTime} - ${endTime}` : formattedTime}
              </span>
            )}
          </div>
        </div>
      );
    });
  };

  if (loading) {
    return (
      <div className="event-detail-page">
        <header className="dashboard-header">
          <div className="dashboard-brand">
            <button 
              className="back-button" 
              onClick={handleBackClick}
              aria-label="Go back"
            >
              <BackArrowIcon />
            </button>
            <h1>Event Details</h1>
          </div>
          <div className="dashboard-header-right">
            <HamburgerMenu onLogout={handleLogout} />
          </div>
        </header>
        
        <div className="event-detail-content">
          <div className="event-detail-loading">
            <div className="loading-spinner"></div>
            <p>Loading event details...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error || !event) {
    return (
      <div className="event-detail-page">
        <header className="dashboard-header">
          <div className="dashboard-brand">
            <button 
              className="back-button" 
              onClick={handleBackClick}
              aria-label="Go back"
            >
              <BackArrowIcon />
            </button>
            <h1>Event Details</h1>
          </div>
          <div className="dashboard-header-right">
            <HamburgerMenu onLogout={handleLogout} />
          </div>
        </header>
        
        <div className="event-detail-content">
          <div className="event-detail-error">
            <p>{error || 'Event not found'}</p>
            <button className="btn-retry" onClick={handleBackClick}>
              Back to Events
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="event-detail-page">
      <header className="dashboard-header">
        <div className="dashboard-brand">
          <button 
            className="back-button" 
            onClick={handleBackClick}
            aria-label="Go back"
          >
            <BackArrowIcon />
          </button>
          <h1>Event Details</h1>
        </div>
        <div className="dashboard-header-right">
          <button 
            className="share-button" 
            onClick={handleShare}
            aria-label="Share event"
          >
            <ShareIcon />
          </button>
          <HamburgerMenu onLogout={handleLogout} />
        </div>
      </header>
      
      {showShareOptions && (
        <div className="share-options">
          <button onClick={() => shareEvent('clipboard')} className="share-option">
            <LinkIcon />
            Copy Link
          </button>
          <button onClick={() => shareEvent('email')} className="share-option">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </svg>
            Email
          </button>
        </div>
      )}
      
      <div className="event-detail-content">
        <div className="event-detail-card">
          <div className="event-detail-header">
            <h2 className="event-detail-title">{event.title}</h2>
            
            <div className="event-detail-dates">
              {formatDates(event.dates)}
            </div>
          </div>
          
          <div className="event-detail-image">
            <img 
              src={event.imageUrl} 
              alt={event.title}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/app/RGB_Red_Icon.png';
              }}
            />
          </div>
          
          <div className="event-detail-body">
            <div 
              className="event-detail-description"
              dangerouslySetInnerHTML={{ __html: formatBodyWithLinks(event.body) }}
            ></div>
          </div>
          
          {event.body && extractLinks(event.body).length > 0 && (
            <div className="event-detail-links">
              <h3>Links</h3>
              <ul>
                {extractLinks(event.body).map((link, index) => {
                  // Clean the URL by removing trailing punctuation
                  const cleanLink = link.replace(/[.,;:!?)]+$/, '');
                  
                  return (
                    <li key={index}>
                      <a 
                        href={cleanLink} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="event-detail-link"
                      >
                        <LinkIcon />
                        {link.length > 40 ? `${link.substring(0, 40)}...` : link}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div> 
        
        <button 
          className="btn-back-to-events"
          onClick={() => navigate('/events')}
        >
          View All Events
        </button>
      </div>
    </div>
  );
};

export default EventDetailPage;