// src/components/ExternalRedirect.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ExternalRedirect.css';

const ExternalRedirect = ({ url, title, description }) => {
  const navigate = useNavigate();
  
  const handleOpenExternal = () => {
    // Open in new tab/window
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  
  return (
    <div className="external-container">
      <header className="external-header">
        <div className="external-header-left">
          <button 
            className="back-button" 
            onClick={() => navigate(-1)}
            aria-label="Go back"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M19 12H5M12 19l-7-7 7-7"/>
            </svg>
          </button>
          <h1>{title}</h1>
        </div>
        <div className="external-header-right">
          <button 
            className="close-button" 
            onClick={() => navigate('/dashboard')}
            aria-label="Return to dashboard"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M18 6L6 18M6 6l12 12"/>
            </svg>
          </button>
        </div>
      </header>
      
      <div className="external-content">
        <div className="external-info">
          <img src="/app/viterbo.png" alt="Viterbo University Logo" className="external-logo" />
          
          <h2>You're about to visit {title}</h2>
          
          <p className="external-description">
            {description || `You will be redirected to the ${title} website in a new browser tab.`}
          </p>
          
          <div className="external-url">
            <span>{url}</span>
          </div>
          
          <div className="external-warning">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
              <line x1="12" y1="9" x2="12" y2="13"></line>
              <line x1="12" y1="17" x2="12.01" y2="17"></line>
            </svg>
            <p>You will be leaving the Viterbo app and opening an external website.</p>
          </div>
          
          <button 
            className="external-button"
            onClick={handleOpenExternal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
              <polyline points="15 3 21 3 21 9"></polyline>
              <line x1="10" y1="14" x2="21" y2="3"></line>
            </svg>
            Open {title}
          </button>
          
          <button 
            className="back-dashboard-button"
            onClick={() => navigate('/dashboard')}
          >
            Return to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExternalRedirect;