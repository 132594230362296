// src/pages/Dashboard.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import InstallButton from '../components/InstallButton';
import CourseSchedule from '../components/CourseSchedule'; 
import EventCarousel from '../components/EventCarousel';
import HamburgerMenu from '../components/HamburgerMenu';
import DesktopNav from '../components/DesktopNav';
import { useAuth } from '../context/AuthContext';
import './Dashboard.css';

// SVG icons as components for better performance
const StudentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path>
    <path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path>
  </svg>
);

const CalendarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="16" y1="2" x2="16" y2="6"></line>
    <line x1="8" y1="2" x2="8" y2="6"></line>
    <line x1="3" y1="10" x2="21" y2="10"></line>
  </svg>
);

const FoodIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M18 8h1a4 4 0 0 1 0 8h-1"></path>
    <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path>
    <line x1="6" y1="1" x2="6" y2="4"></line>
    <line x1="10" y1="1" x2="10" y2="4"></line>
    <line x1="14" y1="1" x2="14" y2="4"></line>
  </svg>
);

const ClassIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
    <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
  </svg>
);

const HomeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
    <polyline points="9 22 9 12 15 12 15 22"></polyline>
  </svg>
);

const SettingsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="3"></circle>
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
  </svg>
);

const RefreshIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="23 4 23 10 17 10"></polyline>
    <polyline points="1 20 1 14 7 14"></polyline>
    <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
  </svg>
);

export default function Dashboard() {
  const navigate = useNavigate();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [activeNavItem, setActiveNavItem] = useState('home');
  const { user, logout, moodleData, moodleLoading, fetchMoodleData } = useAuth();
  const [refreshing, setRefreshing] = useState(false);
  
  // Refs for each section so we can scroll to them
  const topRef = useRef(null); // Reference to the top of the page
  const classesRef = useRef(null);
  const eventsRef = useRef(null); // New ref for events section

  useEffect(() => {
    // Update time every minute
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    
    return () => clearInterval(intervalId);
  }, []);

  // Trigger Moodle data fetch if not already loaded
  useEffect(() => {
    if (!moodleData && !moodleLoading && user) {
      fetchMoodleData();
    }
  }, [user, moodleData, moodleLoading, fetchMoodleData]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await fetchMoodleData();
    setRefreshing(false);
  };

  // Get current date in readable format
  const getCurrentDate = () => {
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    return currentTime.toLocaleDateString('en-US', options);
  };

  // Format greeting based on time of day
  const getGreeting = () => {
    const hour = currentTime.getHours();
    if (hour < 12) return 'Good morning';
    if (hour < 17) return 'Good afternoon';
    return 'Good evening';
  };

  // Get first name from user data
  const getFirstName = () => {
    if (!user) return '';
    
    // Check for name in user object
    if (user.firstName) return user.firstName;
    if (user.displayName) {
      return user.displayName.split(' ')[0];
    }
    
    // Check Moodle data if available
    if (moodleData && moodleData.user) {
      if (moodleData.user.firstname) return moodleData.user.firstname;
      if (moodleData.user.fullname) {
        return moodleData.user.fullname.split(' ')[0];
      }
    }
    
    // Fallback to username or email
    if (user.name) return user.name;
    if (user.email) {
      return user.email.split('@')[0];
    }
    
    return 'Friend'; // Friendly fallback
  };

  // Function to scroll to a specific section
  const scrollToSection = (sectionRef, navItem) => {
    setActiveNavItem(navItem);
    
    if (navItem === 'home') {
      // Handle home navigation specifically
      // Use multiple approaches to ensure cross-browser compatibility
      try {
        // For modern browsers
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        
        // Fallback for older browsers
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        
        // If we have a topRef, try that too
        if (topRef && topRef.current) {
          topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } catch (error) {
        console.error('Error scrolling to top:', error);
        // Simple fallback
        window.scrollTo(0, 0);
      }
      return;
    }
    
    // For other sections
    if (sectionRef && sectionRef.current) {
      try {
        sectionRef.current.scrollIntoView({ behavior: 'smooth' });
      } catch (error) {
        console.error('Error scrolling to section:', error);
        // Try to scroll to element using a different method
        const yOffset = sectionRef.current.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo(0, yOffset);
      }
    }
  };

  // Check if we have Moodle information
  const hasMoodleData = moodleData && 
    ((moodleData.courses && moodleData.courses.length > 0) || 
     (moodleData.course_meetings && moodleData.course_meetings.length > 0));

  return (
    <div className="dashboard" ref={topRef}>
      <header className="dashboard-header">
        <div className="dashboard-brand">
          <div className="dashboard-logo">
            <img src="/app/RGB_Red_Icon.png" alt="Viterbo Logo" />
          </div>
          <h1>Viterbo University</h1>
        </div>
        <div className="dashboard-header-right">
          <InstallButton />
          {/* Hamburger menu moved to right side */}
          <HamburgerMenu onLogout={handleLogout} />
        </div>
      </header>

      {/* Desktop navigation */}
      <DesktopNav />

      <div className="dashboard-content">
        <h2 className="dashboard-greeting">
          {getGreeting()}, {getFirstName()}
        </h2>
        <p>{getCurrentDate()}</p>
        
        {/* Event Carousel Section */}
        <div className="dashboard-card" ref={eventsRef}>
          <EventCarousel />
        </div>
        
        <div className="dashboard-card" ref={classesRef}>
          <h2>
            <ClassIcon /> 
            Your Course Schedule
            {(moodleLoading || refreshing) && (
              <span className="loading-indicator">
                <div className="loading-spinner-small"></div>
              </span>
            )}
          </h2>
          <CourseSchedule />
        </div>

        <div className="dashboard-card">
          <h2><StudentIcon /> Quick Links</h2>
          <div className="quick-links">
            <Link to="/self-service" className="quick-link">
              <div className="quick-link-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <div className="quick-link-content">
                <h3>Self-Service</h3>
                <p>Access your student account, registration, and financial information</p>
                <span className="external-indicator">Opens in new tab</span>
              </div>
            </Link>

            <Link to="/moodle" className="quick-link">
              <div className="quick-link-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
                  <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
                </svg>
              </div>
              <div className="quick-link-content">
                <h3>Moodle</h3>
                <p>Access course materials, assignments, and grades</p>
                <span className="internal-indicator">Opens in app</span>
              </div>
            </Link>

            <Link to="/myvu" className="quick-link">
              <div className="quick-link-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                  <line x1="8" y1="21" x2="16" y2="21"></line>
                  <line x1="12" y1="17" x2="12" y2="21"></line>
                </svg>
              </div>
              <div className="quick-link-content">
                <h3>MyVU</h3>
                <p>Access university resources and information</p>
                <span className="external-indicator">Opens in new tab</span>
              </div>
            </Link>

            <Link to="/events" className="quick-link">
              <div className="quick-link-icon">
                <CalendarIcon />
              </div>
              <div className="quick-link-content">
                <h3>Campus Events</h3>
                <p>View upcoming events and activities at Viterbo</p>
                <span className="internal-indicator">Opens in app</span>
              </div>
            </Link>

            <Link to="/cafeteria" className="quick-link">
              <div className="quick-link-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M18 8h1a4 4 0 0 1 0 8h-1"></path>
                  <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path>
                  <line x1="6" y1="1" x2="6" y2="4"></line>
                  <line x1="10" y1="1" x2="10" y2="4"></line>
                  <line x1="14" y1="1" x2="14" y2="4"></line>
                </svg>
              </div>
              <div className="quick-link-content">
                <h3>Cafeteria Menu</h3>
                <p>View today's dining options and meal schedules</p>
                <span className="external-indicator">Opens in new tab</span>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      <div className="mobile-nav">
        <button 
          className={`mobile-nav-item ${activeNavItem === 'home' ? 'active' : ''}`} 
          onClick={() => {
            // Set active nav item
            setActiveNavItem('home');
            
            // Multiple approaches to ensure scrolling to top works across browsers
            // Smooth scroll to top
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
            
            // Fallbacks for older browsers
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            
            // If we have a topRef, try that too
            if (topRef && topRef.current) {
              topRef.current.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          <div className="mobile-nav-icon"><HomeIcon /></div>
          <span>Home</span>
        </button>
        
        <Link 
          to="/moodle" 
          className={`mobile-nav-item ${activeNavItem === 'moodle' ? 'active' : ''}`} 
          onClick={() => setActiveNavItem('moodle')}
        >
          <div className="mobile-nav-icon"><ClassIcon /></div>
          <span>Moodle</span>
        </Link>
        
        <Link 
          to="/events"
          className={`mobile-nav-item ${activeNavItem === 'events' ? 'active' : ''}`}
          onClick={() => setActiveNavItem('events')} 
        >
          <div className="mobile-nav-icon"><CalendarIcon /></div>
          <span>Events</span>
        </Link>
        
        <Link 
          to="/self-service" 
          className={`mobile-nav-item ${activeNavItem === 'self-service' ? 'active' : ''}`} 
          onClick={() => setActiveNavItem('self-service')}
        >
          <div className="mobile-nav-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
          </div>
          <span>Self-Svc</span>
        </Link>

        <Link 
          to="/user-details" 
          className={`mobile-nav-item ${activeNavItem === 'settings' ? 'active' : ''}`} 
          onClick={() => setActiveNavItem('settings')}
        >
          <div className="mobile-nav-icon"><SettingsIcon /></div>
          <span>Account</span>
        </Link>
      </div>
    </div>
  );
}