// src/components/EventCarousel.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import eventsService from '../services/EventsService';
import './EventCarousel.css';

// Calendar icon component
const CalendarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
    <line x1="16" y1="2" x2="16" y2="6"></line>
    <line x1="8" y1="2" x2="8" y2="6"></line>
    <line x1="3" y1="10" x2="21" y2="10"></line>
  </svg>
);

// Clock icon component
const ClockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="12" cy="12" r="10"></circle>
    <polyline points="12 6 12 12 16 14"></polyline>
  </svg>
);

const EventCarousel = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  // Fetch upcoming events when component mounts
  useEffect(() => {
    let mounted = true;
    
    const fetchUpcomingEvents = async () => {
      try {
        setLoading(true);
        const upcomingEvents = await eventsService.getUpcomingEvents(3);
        
        if (mounted) {
          console.log("Events for carousel:", upcomingEvents);
          setEvents(upcomingEvents);
          setLoading(false);
        }
      } catch (err) {
        console.error('Failed to fetch events:', err);
        if (mounted) {
          setError('Unable to load events at this time');
          setLoading(false);
        }
      }
    };
    
    fetchUpcomingEvents();
    
    // Auto-rotate carousel every 5 seconds if there are events
    const rotationInterval = setInterval(() => {
      if (mounted && events.length > 1) {
        setCurrentIndex(prevIndex => (prevIndex + 1) % events.length);
      }
    }, 5000);
    
    // Clean up
    return () => {
      mounted = false;
      clearInterval(rotationInterval);
    };
  }, [events.length]);

  // Navigate to event details
  const handleEventClick = (event) => {
    navigate(`/events/${encodeURIComponent(event.title)}`);
  };

  // Navigate to next/previous event
  const goToNextEvent = (e) => {
    e.stopPropagation(); // Prevent event click handler
    setCurrentIndex(prevIndex => (prevIndex + 1) % events.length);
  };
  
  const goToPrevEvent = (e) => {
    e.stopPropagation(); // Prevent event click handler
    setCurrentIndex(prevIndex => (prevIndex - 1 + events.length) % events.length);
  };

  // Debug rendering state
  console.log("EventCarousel render state:", { 
    loading, 
    error, 
    eventsCount: events.length,
    currentIndex
  });

  // If loading, show a loading state
  if (loading) {
    return (
      <div className="event-carousel">
        <div className="carousel-header">
          <h3>
            <CalendarIcon />
            <span>Upcoming Events</span>
          </h3>
          <Link to="/events" className="view-all-link">View All</Link>
        </div>
        <div className="carousel-loading">
          <div className="loading-spinner"></div>
          <p>Loading events...</p>
        </div>
      </div>
    );
  }

  // If error, show error state
  if (error) {
    return (
      <div className="event-carousel">
        <div className="carousel-header">
          <h3>
            <CalendarIcon />
            <span>Upcoming Events</span>
          </h3>
          <Link to="/events" className="view-all-link">View All</Link>
        </div>
        <div className="carousel-error">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  // If no events, show empty state
  if (!events || events.length === 0) {
    return (
      <div className="event-carousel">
        <div className="carousel-header">
          <h3>
            <CalendarIcon />
            <span>Upcoming Events</span>
          </h3>
          <Link to="/events" className="view-all-link">View All</Link>
        </div>
        <div className="carousel-empty">
          <p>No upcoming events found</p>
        </div>
      </div>
    );
  }

  // Make sure currentIndex is valid
  const safeIndex = events.length > 0 ? currentIndex % events.length : 0;
  const currentEvent = events[safeIndex];

  // Ensure we have a valid event
  if (!currentEvent) {
    console.error("Invalid current event:", { safeIndex, events });
    return (
      <div className="event-carousel">
        <div className="carousel-header">
          <h3>
            <CalendarIcon />
            <span>Upcoming Events</span>
          </h3>
          <Link to="/events" className="view-all-link">View All</Link>
        </div>
        <div className="carousel-error">
          <p>Error loading event data</p>
        </div>
      </div>
    );
  }

  return (
    <div className="event-carousel">
      <div className="carousel-header">
        <h3>
          <CalendarIcon />
          <span>Upcoming Events</span>
        </h3>
        <Link to="/events" className="view-all-link">View All</Link>
      </div>

      <div 
        className="carousel-container" 
        onClick={() => handleEventClick(currentEvent)}
      >
        {events.length > 1 && (
          <>
            <button 
              className="carousel-nav carousel-prev" 
              onClick={goToPrevEvent}
              aria-label="Previous event"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </button>
            <button 
              className="carousel-nav carousel-next" 
              onClick={goToNextEvent}
              aria-label="Next event"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </button>
          </>
        )}

        <div className="carousel-event">
          <div className="event-image-container">
            <img 
              src={currentEvent.imageUrl} 
              alt={currentEvent.title} 
              className="event-image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/app/event-placeholder.svg';
              }}
            />
          </div>
          <div className="event-info">
            <h4 className="event-title">{currentEvent.title}</h4>
            <div className="event-date-time">
              <ClockIcon />
              <span>{currentEvent.formattedDate}</span>
            </div>
          </div>
        </div>

        {events.length > 1 && (
          <div className="carousel-indicators">
            {events.map((_, index) => (
              <span 
                key={index} 
                className={`carousel-indicator ${index === safeIndex ? 'active' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentIndex(index);
                }}
              ></span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCarousel;