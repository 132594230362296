// src/context/AuthContext.js
import React, { createContext, useState, useEffect, useContext, useCallback } from 'react';

const AuthContext = createContext({
  user: null,
  loading: true,
  error: null,
  moodleData: null,  
  moodleLoading: false,
  refreshUser: () => Promise.resolve(),
  fetchMoodleData: () => Promise.resolve(), 
  setUser: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const localUser = localStorage.getItem('user');
    return localUser ? JSON.parse(localUser) : null;
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refreshInterval, setRefreshInterval] = useState(null);
  
  // New state for Moodle data
  const [moodleData, setMoodleData] = useState(() => {
    const localMoodleData = localStorage.getItem('moodleData');
    return localMoodleData ? JSON.parse(localMoodleData) : null;
  });
  const [moodleLoading, setMoodleLoading] = useState(false);

  const fetchUser = useCallback(async () => {
    setLoading(true);
    setError(null);
    
    // Check if we're in development mode and have a bypassed user
    const localUser = localStorage.getItem('user');
    const parsedUser = localUser ? JSON.parse(localUser) : null;
    
    if (process.env.NODE_ENV === 'development' && parsedUser?.developerAccess) {
      console.log('Development mode: Using local user data instead of API call');
      setUser(parsedUser);
      setLoading(false);
      return parsedUser;
    }
    
    try {
      // For local development without backend, add a timeout and fallback
      const abortController = new AbortController();
      const timeoutId = setTimeout(() => abortController.abort(), 5000);
      
      const response = await fetch('/app/api/user', {
        credentials: 'include',
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
        },
        signal: abortController.signal
      }).finally(() => clearTimeout(timeoutId));
      
      if (!response.ok) {
        throw new Error('User not authenticated');
      }
      
      // Safely try to parse JSON (to handle HTML responses)
      let data;
      const responseText = await response.text();
      try {
        data = JSON.parse(responseText);
      } catch (parseError) {
        console.warn('Response is not valid JSON:', responseText.substring(0, 100) + '...');
        throw new Error('Invalid response format');
      }
      
      // Update localStorage and state
      localStorage.setItem('user', JSON.stringify(data));
      setUser(data);
      
      return data;
    } catch (err) {
      console.error('Error fetching user data:', err);
      
      // Special handling for development mode
      if (process.env.NODE_ENV === 'development') {
        // If we have a user in localStorage, keep using it
        if (parsedUser) {
          console.log('Development mode: Keeping existing user after API error');
          setUser(parsedUser);
          setLoading(false);
          return parsedUser;
        }
      } else {
        // In production, remove user data on auth errors
        if (!err.message.includes('Failed to fetch') && 
            !err.name.includes('AbortError')) {
          localStorage.removeItem('user');
          setUser(null);
        }
      }
      
      setError(err.message);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  // New function to fetch Moodle data for the current user
  const fetchMoodleData = useCallback(async (userData = null) => {
    // If no userData was passed, use the current user state
    const currentUser = userData || user;
    
    // Don't proceed if no user
    if (!currentUser) {
      console.log('No user data available to fetch Moodle data');
      return null;
    }
    
    // Check if we've already tried this username and it failed with 404
    // This prevents continuous retries for usernames that don't exist in Moodle
    const failedUsernames = localStorage.getItem('moodleFailedUsernames');
    const failedUsernamesList = failedUsernames ? JSON.parse(failedUsernames) : [];
    
    setMoodleLoading(true);
    
    try {
      // Determine the username to use for the Moodle lookup
      let username = '';
      
      // Directly use the username if available (from Drupal profile)
      if (currentUser.name) {
        username = currentUser.name;
      } else if (currentUser.email) {
        // If we have an email but no username, extract username from email
        username = currentUser.email.split('@')[0];
      } else if (currentUser.uid) {
        // Last resort, use the UID
        username = `user${currentUser.uid}`;
      }
      
      // Check if this username previously failed with 404
      if (failedUsernamesList.includes(username)) {
        console.log(`Username ${username} previously returned 404, skipping Moodle API call`);
        setMoodleLoading(false);
        return null;
      }
      
      // Special handling for development mode
      if (process.env.NODE_ENV === 'development' && currentUser.developerAccess) {
        console.log('Development mode: Using mock Moodle data');
        
        // Create mock Moodle data for development testing
        const mockMoodleData = {
          user: {
            id: 123,
            username: username,
            fullname: `${currentUser.firstName || ''} ${currentUser.lastName || ''}`,
            email: currentUser.email || 'dev@example.com',
            profileimageurl: '/app/viterbo.png',
          },
          courses: [
            {
              id: 101,
              shortname: 'MATH-101-001',
              fullname: 'Introduction to Mathematics',
              idnumber: 'MATH101',
              startdate: Math.floor(Date.now() / 1000) - 86400 * 30, // 30 days ago
              enddate: Math.floor(Date.now() / 1000) + 86400 * 60,   // 60 days from now
              category: 'Mathematics'
            },
            {
              id: 102,
              shortname: 'BIOL-205-002',
              fullname: 'Advanced Biology',
              idnumber: 'BIOL205',
              startdate: Math.floor(Date.now() / 1000) - 86400 * 30,
              enddate: Math.floor(Date.now() / 1000) + 86400 * 60,
              category: 'Biology'
            }
          ],
          course_meetings: [
            {
              moodle_course_id: 101,
              sec_name: 'MATH-101-001',
              sec_term: 'Spring 2025',
              start_date: '2025-01-15',
              end_date: '2025-05-15',
              meeting_days: 'Mon Wed Fri',
              instruction_method: 'In Person',
              start_time: '10:00 AM',
              end_time: '11:15 AM',
              location: 'Science Hall 305',
              meeting_position: 'Lecture'
            },
            {
              moodle_course_id: 102,
              sec_name: 'BIOL-205-002',
              sec_term: 'Spring 2025',
              start_date: '2025-01-15',
              end_date: '2025-05-15',
              meeting_days: 'Tues Thurs',
              instruction_method: 'In Person',
              start_time: '13:00 PM',
              end_time: '14:15 PM',
              location: 'Science Hall 201',
              meeting_position: 'Lecture'
            }
          ]
        };
        
        localStorage.setItem('moodleData', JSON.stringify(mockMoodleData));
        setMoodleData(mockMoodleData);
        setMoodleLoading(false);
        
        return mockMoodleData;
      }
      
      // Skip if no username could be determined
      if (!username) {
        console.warn('Could not determine username for Moodle API call');
        setMoodleLoading(false);
        return null;
      }
      
      console.log('Fetching Moodle data for username:', username);
      
      // Call the API to get user data and courses
      const response = await fetch(`/app/api/moodle/user-by-username?username=${encodeURIComponent(username)}`, {
        credentials: 'include',
        headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
        }
      });
      
      if (response.status === 404) {
        console.warn(`Username ${username} not found in Moodle (404)`);
        
        // Remember this username failed with 404 to avoid repeated calls
        failedUsernamesList.push(username);
        localStorage.setItem('moodleFailedUsernames', JSON.stringify(failedUsernamesList));
        
        setMoodleLoading(false);
        return null;
      }
      
      if (!response.ok) {
        console.warn(`Failed to fetch Moodle data: ${response.status}`);
        setMoodleLoading(false);
        return null;
      }
      
      const data = await response.json();
      
      if (data.error) {
        console.error('Error in Moodle API response:', data.error);
        setMoodleLoading(false);
        return null;
      }
      
      // Store the data
      localStorage.setItem('moodleData', JSON.stringify(data));
      setMoodleData(data);
      
      // If we have user data from Moodle, update the user object with additional details
      if (data.user) {
        const updatedUser = {
          ...currentUser,
          // Only update these fields if they don't already exist or if Moodle data is available
          firstName: currentUser.firstName || data.user.firstname,
          lastName: currentUser.lastName || data.user.lastname,
          displayName: currentUser.displayName || data.user.fullname,
          email: currentUser.email || data.user.email,
          moodleId: data.user.id,
          profileImage: data.user.profileimageurl
        };
        
        localStorage.setItem('user', JSON.stringify(updatedUser));
        setUser(updatedUser);
      }
      
      return data;
    } catch (err) {
      console.error('Error fetching Moodle data:', err);
      return null;
    } finally {
      setMoodleLoading(false);
    }
  }, [user]);

  const logout = useCallback(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('moodleData'); // Also clear Moodle data
    setUser(null);
    setMoodleData(null);
    
    // In a real app, you might want to call a logout endpoint
    fetch('/app/api/logout', { 
      method: 'POST',
      credentials: 'include',
    }).catch(err => {
      console.log('Logout request failed:', err);
    });
  }, []);

  // Initial fetch on mount
  useEffect(() => {
    let isComponentMounted = true;
    
    // Clean up any existing interval to prevent multiple intervals
    if (refreshInterval) {
      clearInterval(refreshInterval);
    }
    
    const initializeAuth = async () => {
      try {
        const userData = await fetchUser();
        
        // Only proceed if component is still mounted and we have user data
        if (!isComponentMounted || !userData) return;
        
        // Check if we already have Moodle data in localStorage to avoid unnecessary API calls
        const cachedMoodleData = localStorage.getItem('moodleData');
        
        if (cachedMoodleData) {
          try {
            const parsedMoodleData = JSON.parse(cachedMoodleData);
            if (isComponentMounted) {
              setMoodleData(parsedMoodleData);
            }
          } catch (e) {
            console.error('Error parsing cached Moodle data:', e);
            // If there's an error parsing, clear the invalid data
            localStorage.removeItem('moodleData');
            
            // Only fetch if component is still mounted
            if (isComponentMounted) {
              fetchMoodleData(userData);
            }
          }
        } else if (isComponentMounted) {
          // No cached data, fetch fresh data
          fetchMoodleData(userData);
        }
      } catch (err) {
        console.error('Error in initialization:', err);
      }
    };
    
    // Run initialization once
    initializeAuth();
    
    // Setup periodic refresh - less frequent to reduce API load
    const newInterval = setInterval(() => {
      if (!isComponentMounted) return; // Don't run if unmounted
      
      fetchUser().then(userData => {
        if (userData && isComponentMounted) {
          // Only refresh Moodle data every hour (not on every user refresh)
          const lastMoodleRefresh = localStorage.getItem('lastMoodleRefresh');
          const now = Date.now();
          if (!lastMoodleRefresh || (now - parseInt(lastMoodleRefresh, 10)) > 3600000) {
            fetchMoodleData(userData);
            localStorage.setItem('lastMoodleRefresh', now.toString());
          }
        }
      }).catch(err => {
        console.error('Error in refresh interval:', err);
      });
    }, 60000 * 15); // Every 15 minutes
    
    setRefreshInterval(newInterval);
    
    // Cleanup function
    return () => {
      isComponentMounted = false;
      if (newInterval) {
        clearInterval(newInterval);
      }
    };
  }, []); // Empty dependency array - run once on mount only

  return (
    <AuthContext.Provider 
      value={{ 
        user, 
        setUser, 
        loading, 
        error, 
        refreshUser: fetchUser,
        fetchMoodleData,
        moodleData,
        moodleLoading,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);